<!--销售排行-->
<!--销售统计-->
<template>
	<div class="mainBox">
		<!--日期筛选-->
		<div class="handleBox overviewTime flex flex-jc-fe flex-ai-c">
			<el-radio-group v-model="dateRadio" style="margin-right:10px;">
				<el-radio label="week">周</el-radio>
				<el-radio label="month">月</el-radio>
				<el-radio label="year">年</el-radio>
			</el-radio-group>

			<el-date-picker v-show="dateRadio == 'year'" v-model="inputyear" size="mini" type="year" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'month'" v-model="inputmonth" size="mini" type="month" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getList()">
			</el-date-picker>
			<el-date-picker v-show="dateRadio == 'week'" v-model="inputweek" size="mini" type="week" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getList()">
			</el-date-picker>
			<el-input placeholder="搜索销售额前几名" v-model="searchValue" oninput="this.value=this.value.toString().match(/^\d+(?:\d{0})?/)" size="mini" style="width:180px;margin-left: 10px;" @change="curr=1;getList()" class="input-with-select">
				<template slot="prepend">销售额</template>
			</el-input>
			<el-select v-model="houseId" placeholder="门店" size="mini" clearable class="selectWidth" style="margin-left: 8px;" @change="curr=1;getList()">
				<el-option v-for="item in houseList" :label="item.name" :value="item.id" :key="item.id"></el-option>
			</el-select>
		</div>

		<div class="flex" v-if='!isEmpty' style="height:calc(100% - 80px);margin-top:15px">
			<el-row class="houseSaleBox" style="width: 100%;">
				<el-row class="tips">门店单品销量排行</el-row>
				<div ref="barChart" :style="{width: '100%', height: '90%'}"></div>
			</el-row>
		</div>
		
		<div v-if='isEmpty' class="flex flex-column flex-ai-c flex-jc-c" style="width:100%;height:calc(100% - 80px);margin-top:15px">
			<el-image :src="require('../../assets/noData.png')" style="height: 200px;"></el-image>
			<p style="color: #6b6b6b;margin-top: 20px;">暂无数据</p>
		</div>
	</div>
</template>
<script>
	var echarts = require('echarts');
	import { salesRankingApi } from '@/api/overview.js';
	import { getHouseNameApi } from '@/api/house.js';
	export default {
		data() {
			return {
				dateRadio: 'month', //年月周日-默认日
				selectDate: null,

				inputyear: '',
				inputmonth: '',
				inputweek: '',
				inputDay: '',
				type: '',
				houseId:'',
				searchValue:20,

				formData: {},
				houseList:[],
				
				isEmpty:false
			}
		},
		watch: {
			isCollapse() {
				this.resizeWindow();
			},
			'dateRadio': function() {
				var myDate = new Date();
				switch (this.dateRadio) {
					case 'year':
						this.inputyear = String(myDate.getFullYear());
						break;
					case 'month':
						this.inputmonth = (new Date(myDate.getFullYear(), myDate.getMonth(), 1))
						break;
					case 'week':
						this.inputweek = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), (myDate.getDate() - myDate.getDay()) + 1));
						break;
					case 'day':
						this.inputDay = this.dataFormat(new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate()));
						break;
					default:
						break;
				}
				this.getList();
			}
		},
		mounted() {
			this.getHouseName();
			this.init();
			
		},
		methods: {
			//初始化数据
			init() {
				let _this = this;
				this.$nextTick(() => {
					if (_this.dateRadio == 'month') {
						_this.inputmonth = _this.dataFormat(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
						_this.getList();
					}

					window.onresize = () => { //  根据窗口大小调整曲线大小
						_this.resizeWindow();
					}
				})
			},
			//获取各店名称
			getHouseName: function(){
				getHouseNameApi().then(response=>{
					this.houseList = response;
				})
			},
			//窗口改变统计图自适应
			resizeWindow: function() {
				let barChart = echarts.init(this.$refs.barChart);
				barChart.resize();
			},

			//获取统计概况数据
			getList: function() {
				//筛选日期
				this.selectDate = this.dateRadio == 'day' ? this.inputDay : this.dateRadio == 'week' ? this.inputweek : this.dateRadio == 'month' ? this.inputmonth : this.inputyear;
				//筛选类型
				this.type = this.dateRadio == 'day' ? 4 : this.dateRadio == 'week' ? 1 : this.dateRadio == 'month' ? 2 : 3;

				salesRankingApi({
					type: this.type,
					date: this.selectDate,
					warehouse_id:this.houseId,
					top:this.searchValue
				}).then(response => {
					this.formData = response;
					this.isEmpty = response.xAxis.length == 0 ? true : false;
					console.log(this.isEmpty)
					this.$nextTick(() => {
						if(!this.isEmpty){
							this.drawBar();
						}
					})
					
				})
			},


			// 柱形图
			drawBar: function() {
				var myChart = echarts.init(this.$refs.barChart);
				var option = {
					tooltip: {
						trigger: 'axis',
					},
					grid: { //位置
						top: '7%',
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: this.formData.xAxis,
						axisPointer: {
							type: 'shadow'
						}
					}],
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						}
					},
					series: [{
						name: '订单金额',
						type: 'bar',
						data: this.formData.yAxis,
						barWidth: '25%',
						itemStyle: {
							normal: {
								color: '#7B84FF', //设置柱子颜色
								label: {
									show: true, //柱子上显示值
									position: 'top', //值在柱子上方显示
									textStyle: {
										color: '#7B84FF' //值得颜色
									}
								}
							}
						},
					}]
				};
				myChart.setOption(option);
			},

			//饼图
			drawPie: function() {
				var myChart = echarts.init(this.$refs.piechart);
				myChart.setOption({
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						left: 'right',
						top: '25%',
					},
					series: [{
						// name: '销售品类占比',
						type: 'pie',
						radius: '50%',
						data: this.formData.category.data,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				})
			},

			//时间转换
			dataFormat: function(time) {
				return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
			}
		},
	}
</script>
<style lang="less">
	.houseSaleBox {
		border-radius: 3px;
		padding: 20px 25px;
		box-sizing: border-box;
		background: #ffffff;
		height: 100%;

		.tips {
			padding-left: 10px;
			font-size: 17px;
			color: #555;
			position: relative;
			margin-bottom: 20px;

			&::before {
				content: '';
				width: 3px;
				height: 100%;
				background: #f58d35;
				position: absolute;
				top: 0;
				left: 0px;
			}
		}
	}
</style>

